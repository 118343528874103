import CryptoJS from 'crypto-js';

const CryptoKey = '385f33cb91484b04a177828829081ab7';

export function cryptoPassword(password = '') {
	const key = CryptoJS.enc.Utf8.parse(CryptoKey);

	const encryptedContent = CryptoJS.AES.encrypt(password, key, {
		mode: CryptoJS.mode.ECB,
		padding: CryptoJS.pad.Pkcs7,
	});
	const ciphertext = encryptedContent.ciphertext.toString();
	return ciphertext;
}