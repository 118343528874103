<template>
	<div class="views-login">
		<div class="views-login-main M">
			<div class="left-section">
				<div class="system-name">
					<div class="title" @click="setAccount()">钢研碳云平台</div>
					<div class="subtitle">“双碳”技能竞赛系统</div>
				</div>
				<div class="description">
					“双碳”技能竞赛系统，是基于中国钢研开发的“钢研碳云”数字化碳管理平台设计的
					<br />
					稳定高效的无纸化考试系统。
				</div>
				<div class="list">
					<div class="title">钢研碳云平台</div>
					<div class="item">组织碳核算管控</div>
					<div class="item">低碳规划路线制定</div>
					<div class="item">产品碳足迹管理</div>
					<div class="item">碳资产管理与运营</div>
					<div class="item">双碳竞赛教育辅助</div>
					<div class="item">CBAM填报与评估</div>
					<div class="item">低碳发展战略咨询</div>
					<div class="item">碳素流/铁素流仿真</div>
				</div>
			</div>
			<div class="right-section">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
					<div class="login-card">
						<div class="heading">欢迎登录</div>
						<el-form-item prop="username">
							<div class="input-account">
								<el-input v-model="ruleForm.username" placeholder="请输入手机号" clearable autocomplete="new-password"></el-input>
								<svg-icon name="person" />
								<div class="label">账号</div>
							</div>
						</el-form-item>
						<el-form-item prop="password">
							<div class="input-password">
								<el-input v-model="ruleForm.password" placeholder="请输入密码" clearable show-password autocomplete="new-password"></el-input>
								<svg-icon name="shield" />
								<div class="label">密码</div>
							</div>
						</el-form-item>
						<div class="login-btn">
							<el-button type="custom-primary" :loading="isLoginLoading" @click="login">登录</el-button>
						</div>
						<div class="agreement-wrapper">
							<el-form-item prop="checked">
								<div class="agreement">
									<el-checkbox-group v-model="ruleForm.checked">
										<el-checkbox name="checked"><span class="agreement-text">登录即同意</span></el-checkbox>
									</el-checkbox-group>
									<a class="agreement-link" @click="toAgreement(1)">《用户协议》</a>
									和
									<a class="agreement-link" @click="toAgreement(0)">《隐私政策》</a>
								</div>
							</el-form-item>
						</div>
					</div>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
import { Login, AgreementInfo } from '@/common/api.js';
import { cryptoPassword } from '@/common/crypto.js';
export default {
	data() {
		return {
			ruleForm: {
				username: '',
				password: '',
				checked: []
			},
			rules: {
				username: [{ required: true, message: '请填写手机号', trigger: 'blur' }],
				password: [{ required: true, message: '请填写密码', trigger: 'blur' }],
				checked: [{ type: 'array', required: true, message: '请阅读并同意《用户协议》和《隐私政策》', trigger: 'change' }]
			},
			isLoginLoading: false
		};
	},
	methods: {
		toAgreement(type) {
			const routeData = this.$router.resolve({
				name: 'agreement', // 你想要导航到的路由的名称
				query: {
					type: type
				}
			});
			window.open(routeData.href, '_blank');
		},
		setAccount() {
			if (process.env.NODE_ENV === 'development') {
				this.ruleForm.username = 'jingsai1';
				this.ruleForm.password = 'Aa123456';
			}
		},
		login() {
			this.$store.commit('changeToken');
			this.$store.commit('changeUserInfo');
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.isLoginLoading = true;
					let data = {
						username: this.ruleForm.username,
						password: cryptoPassword(this.ruleForm.password)
					};
					Login(data)
						.then((res) => {
							this.$store.commit('changeToken', res.data.accesstoken);
							this.$store.commit('changeUserInfo', res.data);
							this.openUrl({
								name: 'home'
							});
						})
						.finally(() => {
							this.isLoginLoading = false;
						});
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.views-login {
	// width: 100vw;
	min-height: 100vh;
	display: flex;
	// justify-content: center;
	align-items: center;
	background-image: url('~@/assets/img/login-bg.png');
	background-size: cover;
	.views-login-main {
		display: flex;
		justify-content: space-between;
		.left-section {
			display: flex;
			flex-direction: column;
			.system-name {
				display: flex;
				flex-direction: column;
				.title {
					font-family: PingFang SC;
					font-weight: Bold;
					color: #019a7f;
					font-size: 68px;
					line-height: 68px;
					margin-bottom: 30px;
				}
				.subtitle {
					font-family: PingFang SC;
					font-weight: Bold;
					color: #019a7f;
					font-size: 58px;
					line-height: 58px;
					white-space: nowrap;
				}
			}
			.description {
				margin-top: 36px;
				font-family: PingFang SC, PingFang SC;
				font-weight: 500;
				font-size: 14px;
				color: #019a7f;
				line-height: 28px;
			}
			.list {
				margin-top: 36px;
				display: flex;
				flex-wrap: wrap;
				width: 442px;
				height: 258px;
				background: #ffffff;
				border-radius: 9px 9px 9px 9px;
				border: 1px solid rgba(1, 154, 127, 0.2);
				opacity: 0.86;
				box-sizing: border-box;
				padding: 0 0 20px 0;
				.title {
					width: 100%;
					margin: 0 40px 20px 20px;
					border-bottom: 1px solid #d1eee9;
					font-family: PingFang SC, PingFang SC;
					font-weight: bold;
					font-size: 18px;
					color: #163638;
					line-height: 58px;
				}
				.item {
					width: 50%;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: #163638;
					line-height: 40px;
					padding-left: 20px;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					&::before {
						display: block;
						content: '';
						width: 6px;
						height: 6px;
						border-radius: 50%;
						background-color: #163638;
						margin: 0 10px;
					}
				}
			}
		}
		.right-section {
			// margin-left: 346px;
			.login-card {
				width: 420px;
				height: 494px;
				background: #ffffff;
				box-shadow: 0px 14px 44px 0px rgba(162, 192, 221, 0.1), 0px 2px 10px 0px #d8e4f0;
				border-radius: 10px 10px 10px 10px;
				display: flex;
				flex-direction: column;
				box-sizing: border-box;
				padding: 0 42px;
				.heading {
					margin-top: 60px;
					font-family: PingFang SC, PingFang SC;
					font-weight: bold;
					font-size: 34px;
					color: #000000;
					line-height: 34px;
				}
				.input-account {
					margin-top: 50px;
					display: flex;
					align-items: center;
					position: relative;
					.svg-person {
						font-size: 14px;
						position: absolute;
						left: 0;
					}
					.label {
						position: absolute;
						left: 20px;
						font-family: PingFang SC, PingFang SC;
						font-weight: bold;
						font-size: 14px;
						color: #333333;
						line-height: 14px;
					}
					::v-deep {
						.el-input {
							flex: 1;
							.el-input__inner {
								height: 50px;
								padding-left: 72px;
								border-top: none;
								border-left: none;
								border-right: none;
								border-radius: 0;
								font-family: PingFang SC, PingFang SC;
								font-weight: 500;
								font-size: 16px;
								&::placeholder {
									color: #cccccc;
								}
								&:focus {
									border-color: #008f50;
								}
							}
							.el-input__suffix {
								display: flex;
								align-items: center;
								.el-input__suffix-inner {
									display: flex;
									.el-input__icon {
										display: flex;
										align-items: center;
										justify-content: center;
									}
								}
							}
						}
					}
				}
				.input-password {
					margin-top: 35px;
					display: flex;
					align-items: center;
					position: relative;
					.svg-shield {
						font-size: 14px;
						position: absolute;
						left: 0;
					}
					.label {
						position: absolute;
						left: 20px;
						font-family: PingFang SC, PingFang SC;
						font-weight: bold;
						font-size: 14px;
						color: #333333;
						line-height: 14px;
					}
					::v-deep {
						.el-input {
							flex: 1;
							.el-input__inner {
								height: 50px;
								padding-left: 72px;
								border-top: none;
								border-left: none;
								border-right: none;
								border-radius: 0;
								font-family: PingFang SC, PingFang SC;
								font-weight: 500;
								font-size: 16px;
								&::placeholder {
									color: #cccccc;
								}
								&:focus {
									border-color: #008f50;
								}
							}
							.el-input__suffix {
								display: flex;
								align-items: center;
								.el-input__suffix-inner {
									display: flex;
									.el-input__icon {
										display: flex;
										align-items: center;
										justify-content: center;
									}
								}
							}
						}
					}
				}
				.login-btn {
					margin-top: 35px;
					::v-deep {
						.el-button {
							width: 100%;
							height: 52px;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 12px;
							border-radius: 4px 4px 4px 4px;
							font-family: PingFang SC, PingFang SC;
							font-weight: bold;
							font-size: 18px;
						}
					}
				}
				.agreement-wrapper {
					display: flex;
					justify-content: center;
					.agreement {
						margin-top: 50px;
						display: flex;
						justify-content: center;
						font-family: PingFang SC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #666666;
						line-height: 18px;

						::v-deep {
							.el-checkbox {
								display: flex;
								align-items: center;
								.el-checkbox__input {
									display: flex;
									align-items: center;
									&.is-checked {
										.el-checkbox__inner {
											border-color: #008f50;
											background-color: #008f50;
										}
									}
									&.is-focus {
										.el-checkbox__inner {
											border-color: #008f50;
										}
									}
									.el-checkbox__inner {
										border-radius: 50%;
										&:hover {
											border-color: #008f50;
										}
									}
									.el-checkbox__original {
									}
								}
								.el-checkbox__label {
									font-family: PingFang SC, PingFang SC;
									font-weight: 400;
									font-size: 12px;
									color: #666666;
									line-height: 18px;
								}
							}
						}

						.agreement-link {
							font-family: PingFang SC, PingFang SC;
							font-weight: 400;
							font-size: 12px;
							color: #008f50;
							line-height: 18px;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}
</style>
